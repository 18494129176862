import createClient, { Middleware } from "openapi-fetch";

import { paths } from "src/api/schema";
import { getConfig } from "src/config.ts";
import { RootState } from "src/store/store.ts";

const client: ReturnType<typeof createClient<paths>> | null = null;

export const setupClient = (getState: () => RootState) => {
  const authMiddleware: Middleware = {
    async onRequest({ request }) {
      const auth = getState().auth.auth;
      if (auth) {
        request.headers.set("Authorization", "Bearer " + auth.accessToken);
      }
      return request;
    },
  };

  getClient().use(authMiddleware);
};

export const getClient = () => {
  if (client !== null) {
    return client;
  }
  return createClient<paths>({ baseUrl: getConfig().apiUrl });
};
